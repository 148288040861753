/**
 * Main component that sets up state and handles routing.
 */
import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { ToastContainer } from "react-toastify";

import Login from "./pages/login/Login";
import AuthenticatedPageLayout from "./pages/layouts/AuthenticatedPageLayout";

const App = () => {
  return (
    <Suspense fallback={<div></div>}>
      <RecoilRoot>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<AuthenticatedPageLayout />}></Route>
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </RecoilRoot>
    </Suspense>
  );
};

export default App;
