/**
 * Login screen.
 */

import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import rtasksLogo from "../../images/rtasks_logo.png";
import { useState } from "react";
import axios from "../../axios";

const initialValues = { username: "", password: "", error: "" };
const validationSchema = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string().required(),
});

/**
 * Login page component
 */
const Login = () => {
  const { t } = useTranslation();

  const [credentialsError, setCredentialsError] = useState("");

  let clientId = new URLSearchParams(window.location.search).get("clientId");
  let redirect = new URLSearchParams(window.location.search).get("redirect");

  const onSubmit = async ({ username, password }) => {
    initialValues.error = "";
    try {
      const { data } = await axios.post(`/login`, {
        username,
        password,
        clientId,
      });
      // console.log(data);
      let redirectUrl = redirect.startsWith('http') ? redirect : `http://${redirect}`;
      window.location.replace(`${redirectUrl}?code=${data.code}`);
    } catch (error) {
      setCredentialsError(error?.response?.data?.error);
      console.log(error?.response?.data?.error);
    }
  };

  // render login screen.
  return (
    <>
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={rtasksLogo}
              alt="RTasks"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {t("Sign in with RTasks to continue")}
            </h2>
          </div>
          {/* Login form */}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form className="mt-8 space-y-6">
                {/* Username */}
                <div className="rounded-md shadow-sm -space-y-px">
                  <div>
                    <Field
                      name="username"
                      type="text"
                      placeholder={t("Username")}
                      aria-label={t("Username")}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    />
                  </div>
                  {/* Password */}
                  <div>
                    <Field
                      name="password"
                      type="password"
                      placeholder={t("Password")}
                      aria-label={t("Password")}
                      className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    />
                  </div>
                </div>
                {/* Submit button */}
                <div className="mt-6">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {t("Sign in")}
                  </button>
                </div>
                {/* Error messages */}
                {credentialsError && (
                  <p className="text-sm text-red-600">{credentialsError}</p>
                )}
                {(errors.username ||
                  errors.password ||
                  initialValues.error) && (
                  <div className="mt-6 absolute">
                    {errors.username && touched.username && (
                      <p className="text-sm text-red-600">
                        {t("Your username is required.")}
                      </p>
                    )}
                    {errors.password && touched.password && (
                      <p className="text-sm text-red-600">
                        {t("Your password is required.")}
                      </p>
                    )}

                    {initialValues.error && (
                      <p className="text-sm text-red-600">
                        {initialValues.error}
                      </p>
                    )}
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default Login;
