import React from "react";
import { useLocation, Navigate } from "react-router-dom";

const AuthenticatedPageLayout = () => {
  const location = useLocation();

  if (location.pathname === "/") {
    return (
      <Navigate to="/login" replace />
    );
  }
  return <></>;
};

export default AuthenticatedPageLayout;
