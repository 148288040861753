import React from "react";
import { createRoot } from 'react-dom/client';

// Styles
import "typeface-inter";
import "./index.css";

// Translations
import "./i18n";

import { makeServer } from "./server";
import App from "./App";

// Initialize mirage API for development
if (process.env.NODE_ENV === "development") {
  makeServer({ environment: "development" });
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);
