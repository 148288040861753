/**
 * Axios configuration.
 */
import axios from "axios";
import { toast } from "react-toastify";

axios.defaults.baseURL = "/api";

axios.interceptors.request.use((config) => {
  // Add Authorization header if token is present
  // in local storage.
  //   const token = getCookie("authToken");
  //   if (token) {
  //     // eslint-disable-next-line no-param-reassign
  //     config.headers.Authorization = token;
  //   }

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // Remove auth token if 401 is detected

    const { status } = error.response;
    if (status === 401) {
      //   removeCookie("authToken");
    }
    if (status === 400) {
      toast.error(
        error.response.data.error
          ? error.response.data.error
          : error.response.data?.errors[0]?.name
          ? error.response.data?.errors[0]?.name
          : "Something went wrong!",
        {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
    if (status === 403) {
      window.location.href = "/forbidden";
    }

    return Promise.reject(error);
  }
);

export default axios;
